<!-- Footer -->
<footer class="bg-dark text-center text-white">
    <section
      class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
    >

      <div class="me-5 d-none d-lg-block">
        <span>Social Medias:</span>
      </div>
      <div>
        <a href="https://discord.gg/Db8cJpuj" >
          <img [src]="'assets/imgs/discord.png'"
          class="img-fluid img"
          style="width: 20%; float: left;">
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-google"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="" class="me-4 text-reset">
          <i class="fab fa-github"></i>
        </a>
      </div>
    </section>
  
    <section class="">
      <div class="container text-center text-md-start mt-5">
        <div class="row mt-3">
          <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <!-- <h6 class="text-uppercase fw-bold mb-4">
              <i class="fas fa-gem me-3"></i>Pokemon Rebirth Online
            </h6> -->
            <!-- <p>
              Here you can use rows and columns to organize your footer content. Lorem ipsum
              dolor sit amet, consectetur adipisicing elit.
            </p> -->
          </div>
          <!-- Grid column -->
  
          <!-- Grid column -->
  
          <!-- Grid column -->
          <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            <!-- Links -->
            <!-- <h6 class="text-uppercase fw-bold mb-4">
              Useful links
            </h6> -->
            <!-- <p>
              <a href="#!" class="text-reset">Soon</a>
            </p>
            <p>
              <a href="#!" class="text-reset">Soon</a>
            </p> -->
          </div>
          <!-- Grid column -->
  
          <!-- Grid column -->
          <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
            <!-- Links -->
            <!-- <h6 class="text-uppercase fw-bold mb-4">
              Contact
            </h6>
            <p>
              <i class="fas fa-envelope me-3"></i>
              soon
            </p> -->
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </div>
    </section>
    <!-- Section: Links  -->
  
    <!-- Copyright -->
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
      © 2023
      <a class="text-reset fw-bold" href="#">Poke Shadow Online</a>
    </div>
    <!-- Copyright -->
  </footer>
  <!-- Footer -->