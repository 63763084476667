import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  feed: string[] = [];
  lines: string[] = [];
  page = 1;
	pageSize = 4;
	collectionSize: any;
  date = new Date();
  month = this.date.getMonth() + 1;
  year = this.date.getFullYear();
  fileYearMonth = "_" + this.month + "_" + this.year;

  constructor(private http: HttpClient) {
    this.readTextFile('https://pokeshadowonline.net/Activity/Feed.txt', 'FEED');
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  readTextFile(file: string, arrayName: string) {
    let array = [];
    this.http.get(file, {responseType: 'text'}).subscribe(data => {
      this.lines = data.split('\n').map(line => line.trim()).filter(line => line.length > 0);
      this.lines.forEach(element => {
      array.push(element);
    });
    array.sort((a,b) => b.amount - a.amount);
    this.collectionSize = array.length;
    if (array && array.length > 0) {
      this.feed = array.slice(0,10);
    }
    });
}

}
