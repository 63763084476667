<div class="navbar" style="display: block; padding-top: 0; margin-top: 0; background-color: #8b4a9e; border-color: black; border-style: solid; border-width: thin;">
    <img 
    [src]="'assets/imgs/logo.png'"
    class="me-2"
    style="width: 70px"
    />          
    <a routerLinkActive="active" routerLink="/home"><i class="fa fa-fw fa-home"></i> Home</a>
    <a routerLinkActive="active" routerLink="/download"><i class="fa fa-fw fa-home"></i> Download</a>
    <a routerLinkActive="active" routerLink="/features"><i class="fa fa-fw fa-home"></i> Features</a>
    <a routerLinkActive="active" routerLink="/rankings"><i class="fa fa-fw fa-home"></i> Rankings</a>
    <a routerLinkActive="active" routerLink="/battle-pass"><i class="fa fa-fw fa-search"></i> Battle Pass</a>
  </div>