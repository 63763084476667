<div data-aos="fade-up" style="text-align: center; margin-top: 10px;">
    <img [src]="'assets/imgs/featuretext.png'"
    class="img-fluid"
    style="width: 50%"
    />
</div>
<div data-aos="fade-up" style="text-align: center">
    <img [src]="'assets/imgs/separate.png'"
    class="img-fluid"
    style="width: 50%"
    />
</div>
<div class="item" data-aos="fade-up">
    <p class="fonte">Capture and train your favorite monsters any way you want with the exclusive TP System. Any monster can be the best in PSO</p>
    <img [src]="'assets/imgs/tp_system.png'"
    class="img-fluid img"
    />
</div>
<div data-aos="fade-up" style="text-align: center">
    <img [src]="'assets/imgs/separate.png'"
    class="img-fluid"
    style="width: 50%"
    />
</div>
<div class="item" data-aos="fade-down">
    <p class="fonte">Battle gym leaders and other players in fierce battles!</p>
    <img [src]="'assets/imgs/battle.png'"
    class="img-fluid img"
    />
</div>
<div data-aos="fade-up" style="text-align: center">
    <img [src]="'assets/imgs/separate.png'"
    class="img-fluid"
    style="width: 50%"
    />
</div>
<div class="item" data-aos="fade-left">
    <p class="fonte">Explore the Elona region and fight your way to the top!</p>
    <img [src]="'assets/imgs/explore.png'"
    class="img-fluid img"
    />
</div>
<div data-aos="fade-up" style="text-align: center">
    <img [src]="'assets/imgs/separate.png'"
    class="img-fluid"
    style="width: 50%"
    />
</div>
<div class="item" data-aos="fade-left">
    <p class="fonte">Challenge other trainers for the season ladders to see who is the best in every aspect: <span><a routerLinkActive="active" routerLink="/rankings"><i class="fa fa-fw fa-home"></i>Click Here</a></span> to see the current season rankings</p>
    <img [src]="'assets/imgs/rankings.png'"
    class="img-fluid img"
    />
</div>
<div data-aos="fade-up" style="text-align: center">
    <img [src]="'assets/imgs/separate.png'"
    class="img-fluid"
    style="width: 50%"
    />
</div>
<div class="item" data-aos="fade-right">
    <p class="fonte">Customize your character the way you like with hundreds of clothes and hairstyles available</p>
    <img [src]="'assets/imgs/customize.png'"
    class="img-fluid img"
    />
</div>
<div data-aos="fade-up" style="text-align: center">
    <img [src]="'assets/imgs/separate.png'"
    class="img-fluid"
    style="width: 50%"
    />
</div>
<div class="item" data-aos="fade-up">
    <p class="fonte">Create or join a crew and fight other crews for fame and glory</p>
    <img [src]="'assets/imgs/cw.png'"
    class="img-fluid img"
    />
</div>