import { Component, OnInit } from '@angular/core';
import AOS from "aos";

@Component({
  selector: 'app-battle-pass',
  templateUrl: './battle-pass.component.html',
  styleUrls: ['./battle-pass.component.css']
})
export class BattlePassComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }

}
