<div data-aos="fade-up" style="text-align: center; margin-top: 10px;">
    <img [src]="'assets/imgs/battlepasstext.png'"
    class="img-fluid"
    style="width: 50%"
    />
</div>
<section id="background" class="timeline">
    <ul>
      <li>
        <div >
          <time><span style="color: red; font-weight: bolder;">(Battle Pass)</span> 200 Points - Saitama Suit</time>
        </div>
      </li>
      <li>
        <div >
          <time><span style="color: #CD7F32; font-weight: bolder;">(Bronze)</span> 400 Points - </time>
          3 Hero tokens
        </div>
      </li>
      <li>
        <div >
          <time><span style="color: red; font-weight: bolder;">(Battle Pass)</span> 600 Points - 2x God Ball + 2 Hero Token</time>
        </div>
      </li>      
      <li>
        <div >
            <time><span style="color: #C0C0C0; font-weight: bolder;">(Silver)</span> 800 Points - </time>
            5 Hero tokens
          </div>
      </li>
      <li>
        <div >
          <time><span style="color: red; font-weight: bolder;">(Battle Pass)</span> 1000 Points - 2x Stone Box + 5 Hero Tokens</time>
        </div>
      </li>         
      <li>
        <div >
            <time><span style="color: #ffd700; font-weight: bolder;">(Gold)</span> 2000 Points - </time>
            10 Hero tokens
          </div>
      </li>
      <li>
        <div >
          <time><span style="color: red; font-weight: bolder;">(Battle Pass)</span> 3000 Points - 2x IV Reset (All stats) + 10 Hero Tokens</time>
        </div>
      </li>        
      <li>
        <div >
            <time><span style="color: #E5E4E2; font-weight: bolder;">(Platinum)</span> 4500 Points - </time>
            20 Hero tokens
          </div>
      </li>
      <li>
        <div >
          <time><span style="color: red; font-weight: bolder;">(Battle Pass)</span> 6000 Points - Geralt of Rivia Suit + 15 Hero Tokens</time>
        </div>
      </li>        
      <li>
        <div >
            <time><span style="color: #b9f2ff; font-weight: bolder;">(Diamond)</span> 7500 Points - </time>
            50 Hero tokens
          </div>
      </li>
      <li>
        <div >
          <time><span style="color: red; font-weight: bolder;">(Battle Pass)</span> 9000 Points - 2x Beast Box + 30 Hero Tokens</time>
        </div>
      </li>        
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 10500 Points - </time>
            100 Hero tokens
          </div>
      </li>
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 11500 Points - </time>
            5 Hero tokens
          </div>
      </li>
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 12500 Points - </time>
            5 Hero tokens
          </div>
      </li>
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 13500 Points - </time>
            5 Hero tokens
          </div>
      </li>  
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 14500 Points - </time>
            5 Hero tokens
          </div>
      </li>         
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 15500 Points - </time>
            5 Hero tokens
          </div>
      </li>   
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 16500 Points - </time>
            5 Hero tokens
          </div>
      </li>  
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 17500 Points - </time>
            5 Hero tokens
          </div>
      </li>  
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 18500 Points - </time>
            5 Hero tokens
          </div>
      </li>  
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 19500 Points - </time>
            5 Hero tokens
          </div>
      </li>  
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 20500 Points - </time>
            5 Hero tokens
          </div>
      </li> 
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 21500 Points - </time>
            5 Hero tokens
          </div>
      </li>   
      <li>
        <div >
            <time><span style="color: #bf642f; font-weight: bolder;">(Extra)</span> 22500 Points - </time>
            5 Hero tokens
          </div>
      </li>  
    </ul>
  </section>