<div style="height:100%;">  
        
    <h3 style="margin: 20px" >Season 3 Ladders</h3>  
        
    <ngb-tabset [destroyOnHide]="false">  
        
      <ngb-tab style="color: white;" title="Captured Pokémon">  
        <ng-template ngbTabContent> 

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Player Name</th>
                        <th scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let player of PLAYERS_CAPTURED; let indexOfelement=index;">
                        <th scope="row">{{indexOfelement + 1}}</th>
                        <th  scope="row">{{ player.name }}
                            <img *ngIf="indexOfelement == 0"
                            [src]="'assets/imgs/gold-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 1"
                            [src]="'assets/imgs/silver-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 2"
                            [src]="'assets/imgs/bronze-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />                                                        
                        </th>
                        <td >
                            {{ player.amount }}
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <!-- <div class="d-flex justify-content-between p-2">
                <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshPlayers()">
                </ngb-pagination>
            </div> -->
        </ng-template>  
      </ngb-tab>  
         
      <ngb-tab>  
        <ng-template ngbTabTitle>PvP Elo Ranking</ng-template>  
        <ng-template ngbTabContent>  
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th  scope="col">#</th>
                        <th  scope="col">Player Name</th>
                        <th  scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let player of ELO; let indexOfelement=index;">
                        <th  scope="row">{{indexOfelement + 1}}</th>
                        <th  scope="row">{{ player.name }}
                            <img *ngIf="indexOfelement == 0"
                            [src]="'assets/imgs/gold-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 1"
                            [src]="'assets/imgs/silver-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 2"
                            [src]="'assets/imgs/bronze-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />                                                        
                        </th>
                        <td >
                            {{ player.amount }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>  
      </ngb-tab>  
         
      <ngb-tab>  
        <ng-template ngbTabTitle>Rare Pokémon Captured</ng-template>  
        <ng-template ngbTabContent>  
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th  scope="col">#</th>
                        <th  scope="col">Player Name</th>
                        <th  scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let player of PLAYERS_CAPTURED_RARE; let indexOfelement=index;">
                        <th  scope="row">{{indexOfelement + 1}}</th>
                        <th  scope="row">{{ player.name }}
                            <img *ngIf="indexOfelement == 0"
                            [src]="'assets/imgs/gold-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 1"
                            [src]="'assets/imgs/silver-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 2"
                            [src]="'assets/imgs/bronze-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />                                                        
                        </th>
                        <td >
                            {{ player.amount }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>  
      </ngb-tab>
      <ngb-tab>  
        <ng-template ngbTabTitle>Fainted Pokémon</ng-template>  
        <ng-template ngbTabContent>  
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th  scope="col">#</th>
                        <th  scope="col">Player Name</th>
                        <th  scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let player of PLAYERS_KILLED; let indexOfelement=index;">
                        <th  scope="row">{{indexOfelement + 1}}</th>
                        <th  scope="row">{{ player.name }}
                            <img *ngIf="indexOfelement == 0"
                            [src]="'assets/imgs/gold-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 1"
                            [src]="'assets/imgs/silver-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 2"
                            [src]="'assets/imgs/bronze-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />                                                        
                        </th>
                        <td >
                            {{ player.amount }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>  
      </ngb-tab>
      <ngb-tab>  
        <ng-template ngbTabTitle>Total Unique Pokémon Captured</ng-template>  
        <ng-template ngbTabContent>  
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th  scope="col">#</th>
                        <th  scope="col">Player Name</th>
                        <th  scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let player of PLAYERS_CAPTURED_TOTAL; let indexOfelement=index;">
                        <th  scope="row">{{indexOfelement + 1}}</th>
                        <th  scope="row">{{ player.name }}
                            <img *ngIf="indexOfelement == 0"
                            [src]="'assets/imgs/gold-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 1"
                            [src]="'assets/imgs/silver-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 2"
                            [src]="'assets/imgs/bronze-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />                                                        
                        </th>
                        <td >
                            {{ player.amount }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>  
      </ngb-tab>
      <ngb-tab>  
        <ng-template ngbTabTitle>Total Unique Pokémon Encounterd</ng-template>  
        <ng-template ngbTabContent>  
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th  scope="col">#</th>
                        <th  scope="col">Player Name</th>
                        <th  scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let player of PLAYERS_ENCOUNTERED_TOTAL; let indexOfelement=index;">
                        <th  scope="row">{{indexOfelement + 1}}</th>
                        <th  scope="row">{{ player.name }}
                            <img *ngIf="indexOfelement == 0"
                            [src]="'assets/imgs/gold-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 1"
                            [src]="'assets/imgs/silver-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 2"
                            [src]="'assets/imgs/bronze-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />                                                        
                        </th>
                        <td >
                            {{ player.amount }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>  
      </ngb-tab>              
      <ngb-tab>  
        <ng-template ngbTabTitle>Exp. Gained</ng-template>  
        <ng-template ngbTabContent>  
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th  scope="col">#</th>
                        <th  scope="col">Player Name</th>
                        <th  scope="col">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let player of PLAYERS_EXP; let indexOfelement=index;">
                        <th  scope="row">{{indexOfelement + 1}}</th>
                        <th  scope="row">{{ player.name }}
                            <img *ngIf="indexOfelement == 0"
                            [src]="'assets/imgs/gold-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 1"
                            [src]="'assets/imgs/silver-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />
                            <img *ngIf="indexOfelement == 2"
                            [src]="'assets/imgs/bronze-cup.png'"
                            class="me-2"
                            style="width: 50px"
                            />                                                        
                        </th>
                        <td >
                            {{ player.amount }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>  
      </ngb-tab>         
         
    </ngb-tabset>  
</div>  