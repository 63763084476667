<a routerLink="/download">
<div style="text-align: center; margin-top: 10px;">
    <img [src]="'assets/imgs/play-now.png'"
    class="img-fluid"
    style="width: 50%"
    />
</div>
</a>

<div id="feed" class="item">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Latest Server Activity</th>
            </tr>
        </thead>
        <tbody >
            <tr *ngFor="let line of feed;">
                <p style="font-size: medium; font-weight: bold;" scope="row">{{line}}</p>
            </tr>
        </tbody>
    </table>
</div>
