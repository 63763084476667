import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RankingsComponent } from './rankings/rankings.component';
import { BattlePassComponent } from './battle-pass/battle-pass.component';
import { FeaturesComponent } from './features/features.component';
import { HomeComponent } from './home/home.component';
import { DownloadComponent } from './download/download.component';


const routes: Routes = [
  {path: '', redirectTo: "/home", pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'download', component: DownloadComponent},
  {path: 'features', component: FeaturesComponent},
  {path: 'rankings', component: RankingsComponent},
  {path: 'battle-pass', component: BattlePassComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
