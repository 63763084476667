import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface player {
  name: string;
  amount: number;
}

export type playerList = Array<player>;


@Component({
  selector: 'app-rankings',
  templateUrl: './rankings.component.html',
  styleUrls: ['./rankings.component.css']
})
export class RankingsComponent implements OnInit {

  PLAYERS_CAPTURED: playerList;
  PLAYERS_CAPTURED_RARE: playerList;
  PLAYERS_EXP: playerList;
  PLAYERS_KILLED: playerList;
  ELO: playerList;
  PLAYERS_CAPTURED_TOTAL: playerList;
  PLAYERS_ENCOUNTERED_TOTAL: playerList;
  lines: string[] = [];
  page = 1;
	pageSize = 4;
	collectionSize: any;
  date = new Date();
  month = this.date.getMonth() + 1;
  year = this.date.getFullYear();
  fileYearMonth: string
  array = [];


  constructor(private http: HttpClient) { 
    this.fileYearMonth = "_" + this.month + "_" + this.year;
    this.readTextFile('https://pokeshadowonline.net/Captured' + this.fileYearMonth + '.ini', 'PLAYERS_CAPTURED');
    this.readTextFile('https://pokeshadowonline.net/Exp_Gained' + this.fileYearMonth + '.ini', 'PLAYERS_EXP');
    this.readTextFile('https://pokeshadowonline.net/pokemons_killed' + this.fileYearMonth + '.ini', 'PLAYERS_KILLED');
    this.readTextFile('https://pokeshadowonline.net/ELO' + this.fileYearMonth + '.ini', 'ELO');
    this.readTextFile('https://pokeshadowonline.net/Captured_Rares.ini', 'PLAYERS_CAPTURED_RARE');
    this.readTextFile('https://pokeshadowonline.net/Captured_Total.ini', 'PLAYERS_CAPTURED_TOTAL');
    this.readTextFile('https://pokeshadowonline.net/Encountered_Total.ini', 'PLAYERS_ENCOUNTERED_TOTAL');
  }

  ngOnInit(): void {
  }

  readTextFile(file: string, arrayName: string) {
    console.log(arrayName);
    if (arrayName == 'PLAYERS_CAPTURED') {
    this.http.get(file, { responseType: 'text' }).subscribe((content: string) => {
      let linesArray = content.split('\n'); // Split the content into an array of lines

      // Remove empty lines, skip the first line, and parse key-value pairs into objects
      this.PLAYERS_CAPTURED = linesArray
        .filter((_, index) => index !== 0 && _.trim() !== '') // Remove empty lines and skip the first line
        .map(line => {
          const [name, value] = line.split('='); // Split key-value pairs
  
          const amount = value ? Number(value.replace(/\D/g, '')) : null; // Extract numeric characters only if value is not null or undefined
  
          return { name, amount }; // Create object with name and amount attributes
        })
        .sort((a, b) => b.amount - a.amount); // Sort the array by the amount attribute in descending order
  
      // Do something with the objects array
      if (this.PLAYERS_CAPTURED[0].name.includes('<script')) {
        this.PLAYERS_CAPTURED = [];
      }
    });
  } else if (arrayName == 'PLAYERS_EXP') {
    this.http.get(file, { responseType: 'text' }).subscribe((content: string) => {
      let linesArray = content.split('\n'); // Split the content into an array of lines

      // Remove empty lines, skip the first line, and parse key-value pairs into objects
      this.PLAYERS_EXP = linesArray
        .filter((_, index) => index !== 0 && _.trim() !== '') // Remove empty lines and skip the first line
        .map(line => {
          const [name, value] = line.split('='); // Split key-value pairs
  
          const amount = value ? Number(value.replace(/\D/g, '')) : null; // Extract numeric characters only if value is not null or undefined
  
          return { name, amount }; // Create object with name and amount attributes
        })
        .sort((a, b) => b.amount - a.amount); // Sort the array by the amount attribute in descending order
    });
  } else if (arrayName == 'PLAYERS_KILLED') {
    this.http.get(file, { responseType: 'text' }).subscribe((content: string) => {
      let linesArray = content.split('\n'); // Split the content into an array of lines

      // Remove empty lines, skip the first line, and parse key-value pairs into objects
      this.PLAYERS_KILLED = linesArray
        .filter((_, index) => index !== 0 && _.trim() !== '') // Remove empty lines and skip the first line
        .map(line => {
          const [name, value] = line.split('='); // Split key-value pairs
  
          const amount = value ? Number(value.replace(/\D/g, '')) : null; // Extract numeric characters only if value is not null or undefined
  
          return { name, amount }; // Create object with name and amount attributes
        })
        .sort((a, b) => b.amount - a.amount); // Sort the array by the amount attribute in descending order
    });
  } else if (arrayName == 'ELO') {
    this.http.get(file, { responseType: 'text' }).subscribe((content: string) => {
      let linesArray = content.split('\n'); // Split the content into an array of lines
      console.log(content);
      // Remove empty lines, skip the first line, and parse key-value pairs into objects
      this.ELO = linesArray
        .filter((_, index) => index !== 0 && _.trim() !== '') // Remove empty lines and skip the first line
        .map(line => {
          const [name, value] = line.split('='); // Split key-value pairs
  
          const amount = value ? Number(value.replace(/\D/g, '')) : null; // Extract numeric characters only if value is not null or undefined
  
          return { name, amount }; // Create object with name and amount attributes
        })
        .sort((a, b) => b.amount - a.amount); // Sort the array by the amount attribute in descending order
  
      // Do something with the objects array
      if (this.ELO[0].name.includes('<script')) {
        this.ELO = [];
      }
    });
  } else if (arrayName == 'PLAYERS_CAPTURED_RARE') {
    this.http.get(file, { responseType: 'text' }).subscribe((content: string) => {
      let linesArray = content.split('\n'); // Split the content into an array of lines

      // Remove empty lines, skip the first line, and parse key-value pairs into objects
      this.PLAYERS_CAPTURED_RARE = linesArray
        .filter((_, index) => index !== 0 && _.trim() !== '') // Remove empty lines and skip the first line
        .map(line => {
          const [name, value] = line.split('='); // Split key-value pairs
  
          const amount = value ? Number(value.replace(/\D/g, '')) : null; // Extract numeric characters only if value is not null or undefined
  
          return { name, amount }; // Create object with name and amount attributes
        })
        .sort((a, b) => b.amount - a.amount); // Sort the array by the amount attribute in descending order
    });
  } else if (arrayName == 'PLAYERS_CAPTURED_TOTAL') {
    this.http.get(file, { responseType: 'text' }).subscribe((content: string) => {
      let linesArray = content.split('\n'); // Split the content into an array of lines

      // Remove empty lines, skip the first line, and parse key-value pairs into objects
      this.PLAYERS_CAPTURED_TOTAL = linesArray
        .filter((_, index) => index !== 0 && _.trim() !== '') // Remove empty lines and skip the first line
        .map(line => {
          const [name, value] = line.split('='); // Split key-value pairs
  
          const amount = value ? Number(value.replace(/\D/g, '')) : null; // Extract numeric characters only if value is not null or undefined
  
          return { name, amount }; // Create object with name and amount attributes
        })
        .sort((a, b) => b.amount - a.amount); // Sort the array by the amount attribute in descending order
    });
  } else if (arrayName == 'PLAYERS_ENCOUNTERED_TOTAL') {
    this.http.get(file, { responseType: 'text' }).subscribe((content: string) => {
      let linesArray = content.split('\n'); // Split the content into an array of lines

      // Remove empty lines, skip the first line, and parse key-value pairs into objects
      this.PLAYERS_ENCOUNTERED_TOTAL = linesArray
        .filter((_, index) => index !== 0 && _.trim() !== '') // Remove empty lines and skip the first line
        .map(line => {
          const [name, value] = line.split('='); // Split key-value pairs
  
          const amount = value ? Number(value.replace(/\D/g, '')) : null; // Extract numeric characters only if value is not null or undefined
  
          return { name, amount }; // Create object with name and amount attributes
        })
        .sort((a, b) => b.amount - a.amount); // Sort the array by the amount attribute in descending order

    });
  }
  
    // this.collectionSize = array.length;
    };
}
